<template>
  <div>
    <div
      id="carouselExampleCaptions"
      class="carousel slide"
      data-ride="carousel"
    >
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleCaptions"
          data-slide-to="0"
          class="active"
        ></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            src="@/assets/images/slider/image1.jpg"
            class="d-block w-100"
            alt="..."
          />
          <!-- <div class="carousel-caption d-none d-md-block">
          <h5>First slide label</h5>
          <p>Some representative placeholder content for the first slide.</p>
        </div> -->
        </div>
        <div class="carousel-item">
          <img
            src="@/assets/images/slider/image2.jpg"
            class="d-block w-100"
            alt="..."
          />
          <!-- <div class="carousel-caption d-none d-md-block">
          <h5>Second slide label</h5>
          <p>Some representative placeholder content for the second slide.</p>
        </div> -->
        </div>
        <div class="carousel-item">
          <img
            src="@/assets/images/slider/image3.jpg"
            class="d-block w-100"
            alt="..."
          />
          <!-- <div class="carousel-caption d-none d-md-block">
          <h5>Third slide label</h5>
          <p>Some representative placeholder content for the third slide.</p>
        </div> -->
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleCaptions"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleCaptions"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>

    <section class="mb-5">
      <div class="container mb-3">
        <div class="row">
          <div class="col-lg-4 mt-3">
            <a href="http://funbox.zed.com/Bangladesh/Subscribe">
              <img src="@/assets/images/subscribe.svg" class="img img-fluid"
            /></a>
          </div>
          <div class="col-lg-12">
            <div class="my-4 text-center">
              <h1 class="text-uppercase">popular</h1>
              <div class="horizontal-divider"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row" v-if="games.length <= 0">
          <div class="col-lg-4" v-for="i in 9" :key="i">
            <SkeletonThumbnail height="200px" />
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-lg-4" v-for="(game, idx) in games" :key="idx">
            <GameThumbnail :gameDetails="game" v-if="idx <= 8" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-2 offset-md-5">
            <router-link
              class="btn btn-sm btn-candy-grape btn-block"
              to="/all-games"
              >View All Games</router-link
            >
          </div>
        </div>
      </div>
    </section>

    <section class="mb-5">
      <div class="container mb-3">
        <div class="row">
          <div class="col-lg-12">
            <div class="my-4 text-center">
              <h1 class="text-uppercase">latest</h1>
              <div class="horizontal-divider"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row" v-if="latestGame.length <= 0">
          <div class="col-lg-3" v-for="i in 4" :key="i">
            <SkeletonThumbnail height="159px" />
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-lg-3" v-for="(game, idx) in latestGame" :key="idx">
            <GameThumbnail :gameDetails="game" v-if="idx <= 3" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import GameThumbnail from "../components/GameThumbnail.vue";
import SkeletonThumbnail from "../components/SkeletonThumbnail.vue";
// import axios from "axios";
export default {
  components: {
    GameThumbnail,
    SkeletonThumbnail,
  },

  data() {
    return {
      games: [],
      latestGame: [],
    };
  },

  methods: {
    async getGames() {
      await this.$api
        .get("/funbox?country=BD")
        .then((response) => {
          // console.log(response);
          this.games = response.data;
          this.latestGame = response.data.reverse();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async send(sp, pixel, pub, pay) {
      let cId = "";
      if (this.campaignId == "gp1") {
        cId = "FB1";
      } else if (this.campaignId == "gp2") {
        cId = "FB2";
      } else if (this.campaignId == "gp3") {
        cId = "FB3";
      } else if (this.campaignId == "gp4") {
        cId = "FB4";
      } else if (this.campaignId == "gp5") {
        cId = "FB5";
      } else {
        cId = "FB1";
      }

      const {
        data: { ip },
      } = await axios.get("https://www.cloudflare.com/cdn-cgi/trace", {
        responseType: "text",
        transformResponse: (data) =>
          Object.fromEntries(
            data
              .trim()
              .split("\n")
              .map((line) => line.split("="))
          ),
      });

      await this.$api
        .get(
          `/ADNETWORK_FUNBOX?campaignID=${cId}&SP=${sp}&PIXEL=${pixel}&PUB=${pub}&PAY=${pay}&ip=${ip}`
        )
        .then((response) => {
          // console.log(response.data.clickID);
          const clickId = response.data.clickID;
          this.$store.dispatch("ADD_CLICKID", clickId);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  created() {
    this.getGames();
    const query = this.$route.query;
    if (Object.keys(query).length) {
      this.send(query.sp, query.pixel, query.pub, query.pay);
    }
    //
  },
};
</script>
